import * as React from "react"
import Content from "./content"

const Cooky = ({ title, img, pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="text_page">
          <div className="content">
            <div className="text-img">
              <img src={img} alt="Cookie Policy" />
              <h1 className="heading-h1 semi">{title}</h1>
            </div>
            <div className="all-content">
              <Content heading={translate.t?.cookie_phrase_1}>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_2}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_3}
                </p>

                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_4}
                </p>

                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_5}
                </p>

                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_6}
                </p>
              </Content>

              <Content heading={translate.t?.cookie_phrase_7}>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_8}
                </p>
              </Content>

              <Content heading={translate.t?.cookie_phrase_9}>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_10}
                </p>
              </Content>

              <Content heading={translate.t?.cookie_phrase_11}>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_12}
                </p>
              </Content>

              <Content heading={translate.t?.cookie_phrase_13}>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_14}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_15}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_16}{" "}
                  <a
                    href="https://optout.networkadvertising.org/?c=1"
                    title="https://optout.networkadvertising.org/?c=1"
                  >
                    {translate.t?.cookie_phrase_17}
                  </a>
                  .
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_18}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_19}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_20}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_21}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_22}
                </p>
                <p className="text-16 text-medium">
                  {translate.t?.cookie_phrase_23}
                </p>
              </Content>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Cooky
